import {useSelector} from 'react-redux';

import {RootState} from '@/src/lib/store/store';
import {useGetSessionQuery} from '@/src/lib/store/stores/api';

export const useSession = () => {
  const session = useSelector((state: RootState) => state.session.data);

  const {data} = useGetSessionQuery(undefined, {
    skip: !!session?.CSRFToken,
  });

  return session || data;
};
