import {useCallback} from 'react';

import {UserData} from '@/src/interfaces/types/user.types';

export type ITrackingStateType = ITrackingState;

export interface ITrackingState {
  gtmTrack: (props: GtmEventInterface) => void;
}

export interface GtmEventInterface {
  event?: string;
  [propName: string]: string | undefined | VoidFunction | number | string[] | unknown | null | boolean;
}

const useTracking = (params?: {userData?: UserData}): ITrackingState => {
  const {userData} = params || {};

  const gtmTrack = useCallback(
    (props: GtmEventInterface): void => {
      const dataLayer = (window as any).dataLayer || [];
      const vtc = document.cookie.match('_vtc=([^;]*)');

      const eventData = {
        user_id: userData?.id,
        vtc_key: vtc && vtc[1],
        ...props,
      };

      dataLayer.push(eventData);
    },
    [userData?.id]
  );

  return {gtmTrack};
};

export default useTracking;
