export enum GTMEvents {
  begin_checkout = 'begin_checkout',
  toggle_editing = 'click_payment_toggle_editing',
  pageview_brief = 'pageview_wizard_brief',
  pageview_languages = 'pageview_wizard_languages',
  pageview_materials = 'pageview_wizard_materials',
  expertise_selected_FT = 'funnel_FT_service_type',
  click_get_quote = 'click_wizard_get_quote',
  wc_updated = 'user_engagement_wizard_wc_updated',
  ft_languages_select = 'funnel_FT_choose_languages',
  brief_entered = 'user_engagement_wizard_brief_entered',
  file_upload = 'user_engagement_wizard_file_upload',
  file_validation_error = 'notification_wizard_file_validation_error',
  yes_button = 'click_wizard_yes_button',
  no_button = 'click_wizard_no_button',
  click_finish_later = 'click_wizard_finish_po_later_link',
  sent_finish_later = 'user_engagement_wizard_finish_po_later_link_sent',
  expand_finish_later = 'user_engagement_wizard_finish_po_later_expanded',
  pageviewPlans = 'pageview_wizard_plans',
  purchaseEdit = 'click_shopping_cart_edit_purchase_order',
  pageview_thankyou = 'pageview_thankyou',
  pageview_thankyou_project = 'pageview_thankyou_project',
  click_shopping_cart_success_purchase_from_checkout = 'click_shopping_cart_success_purchase_from_checkout',
  purchase = 'purchase',
  first_purchase = 'first_purchase',
  non_first_purchase = 'non_first_purchase',
  click_sale_package = 'click_sale_package',
  sale_package_checkout = 'sale_package_checkout',
  excel_files_reported = 'excel_files_reported',
  checkout_download_proposal = 'checkout_download_proposal',
  click_chat = 'click_sidebar_blend_talk',
  click_create_new_chat = 'click_chat_create_new_chat',
  click_chat_add_members = 'click_chat_add_members',
  click_chat_list_unread_messages = 'click_chat_list_unread_messages',
  click_pin_chat = 'click_chat_pin_chat',
  click_un_pin_chat = 'click_chat_unpin_chat',
  click_chat_file_download = 'click_chat_file_download',
  click_chat_add_new_member = 'click_chat_add_new_member',
  focus_chat_list_search_input = 'focus_chat_list_search_input',
  click_reply_message = 'click_chat_reply_message',
  click_chat_search_icon = 'click_chat_search_icon',
  focus_chat_search_input = 'focus_chat_search_input',
  click_chat_files_icon = 'click_chat_files_icon',
  click_edit_chat_name = 'click_chat_edit_chat_name',
  click_save_chat_name = 'click_chat_save_chat_name',
  additional_service_selected = 'click_payment_additional_service',
  pageview_payment = 'pageview_payment',
  expedite_selected = 'click_payment_expedite',
  click_payment_methods = 'click_payment_methods',
  coupon_applied = 'click_payment_coupon_applied',
  coupon_removed = 'click_payment_coupon_removed',
  pageview_registration = 'pageview_registration',
  purchase_delete = 'click_shopping_cart_delete_purchase_order',
  project_create = 'click_shopping_cart_create_project',
  checkout = 'click_shopping_cart_checkout',
  header_click = 'click_shopping_cart_button_in_header',
  pageview_wizard = 'pageview_wizard2',
  filestack_file_select = 'wizard_filestack_click_file_select',
  funnel_FT_upload_file_fail = 'funnel_FT_upload_file_fail',
  page_view_brief_reference_upload = 'pageview_wizard_brief_reference_upload',
  page_view_brief_reference_delete = 'pageview_wizard_brief_reference_delete',
  translation_type_selected = 'user_engagement_wizard_translation_type_selected',
  click_brief_submit = 'click_wizard_brief_submit',
  plain_text_entered = 'user_engagement_wizard_plain_text_entered',
  entry_value_selected = 'user_engagement_wizard_entry_value_selected',
  funnel_FT_view_pricing_plans = 'funnel_FT_view_pricing_plans',
  funnel_FT_choose_plan = 'funnel_FT_choose_plan',
  filestack_file_upload = 'wizard_filestack_click_file_upload',
  funnel_FT_entry = 'funnel_FT_entry',
  funnel_FT_upload_file_success = 'funnel_FT_upload_file_success',
  funnel_FT_upload_content = 'funnel_FT_upload_content',
  signup_fail = 'signup_fail',
  login_fail = 'login_fail',
  login_success = 'login_success',
  registration = 'registration',
  signup_success = 'signup_success',
  logout = 'logout',
  page_view = 'page_view',
}
