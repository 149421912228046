import {FC} from 'react';

import FormHelperText from '@mui/material/FormHelperText';

interface Props {
  message: string;
}

const ErrorMessageText: FC<Props> = ({message}) => <>{message && <FormHelperText error>{message}</FormHelperText>}</>;

export default ErrorMessageText;
